/** @jsx jsx */
import { jsx, Box, Image } from "theme-ui";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet";
import { useCallback } from "react";
import PropTypes from "prop-types";
import PositionedImage from "../../assets/bannerPositionedImage.png";
import BackgroundImage from "../../assets/bannerBackgroundImage.jpg";
import KeycloakAuth from "../../layouts/keycloak-auth";

const PublicHeader = ({ homeUrl }) => {
  const goHome = useCallback(() => navigate(homeUrl), [homeUrl]);
  return (
    <div sx={{ backgroundColor: "white", mb: 3 }}>
      <Helmet>
        <style>{"body { background-color: #E0CDD7; min-height: 100vh }"}</style>
      </Helmet>
      <KeycloakAuth />
      <Box
        sx={{
          width: "100%",
          background: `#e7dad2 url(${BackgroundImage}) no-repeat center / cover`,
          backgroundPosition: "center top",
          minHeight: "72px",
        }}
      >
        <div>
          <Image
            sx={{ p: 3, minWidth: "200px", width: "25vw", cursor: "pointer" }}
            src={PositionedImage}
            alt="Heartfulness"
            role="button"
            tabIndex={0}
            onClick={goHome}
            onKeyDown={goHome}
          />
        </div>
      </Box>
    </div>
  );
};

PublicHeader.propTypes = {
  homeUrl: PropTypes.string,
};
PublicHeader.defaultProps = {
  homeUrl: "/",
};
export default PublicHeader;
