let firebaseAuthConfig;
let mySrcmConfig;
let firebaseStoreConfig;
let donationServiceConfig;
// let firebaseServiceConfig;
let myDomain;
let siteUrl;
let siteDomain;
let sitePathPrefix;

let cloudFunctions;
let keycloakConfig;
let myloginType;
// const siteFolder = process.env.GATSBY_SITE_FOLDER_NAME;

const prod = process.env.GATSBY_ENV === "prod";

const stagingEnv = prod ? {} : require("./loadEnvStaging")();

const careapp = process.env.GATSBY_BUILD_FOLDER === "careapp";

try {
  keycloakConfig = JSON.parse(process.env.GATSBY_KEYCLOAK_AUTH_CONFIG);
  if (!keycloakConfig) {
    throw Error("error");
  }
} catch (e) {
  if (!prod) {
    keycloakConfig = JSON.parse(stagingEnv.GATSBY_KEYCLOAK_AUTH_CONFIG);
  }
}

try {
  myloginType = process.env.GATSBY_MY_SITE_LOGIN_TYPE;
  if (!myloginType) {
    throw Error("error");
  }
} catch (e) {
  if (!prod) {
    myloginType = stagingEnv.GATSBY_MY_SITE_LOGIN_TYPE;
  }
}
// try {
//   firebaseServiceConfig = JSON.parse(
//     process.env.GATSBY_FIREBASE_SERVICE_CONFIG
//   );
// } catch (e) {
//   try {
//     if (!prod) {
//       // firebaseServiceConfig = require("../../firebase-service-secret.json");
//       firebaseServiceConfig = null;
//     }
//   } catch (e1) {
//     // console.error("ERROR: firebaseServiceConfig is MISSING : Either set env variable GATSBY_FIREBASE_SERVICE_CONFIG or create the local file firebase-service-secret.json")
//     firebaseServiceConfig = null;
//   }
// }

try {
  firebaseAuthConfig = JSON.parse(process.env.GATSBY_FIREBASE_AUTH_CONFIG);
} catch (e) {
  if (!prod) {
    firebaseAuthConfig = JSON.parse(stagingEnv.GATSBY_FIREBASE_AUTH_CONFIG);
  }
}

try {
  const base = process.env.GATSBY_CLOUD_FUNC_BASE;
  const paths = JSON.parse(process.env.GATSBY_CLOUD_FUNC_PATHS);
  if (!base || !paths) {
    throw Error("error");
  }
  cloudFunctions = Object.fromEntries(
    Object.entries(paths).map(([k, p]) => [k, base + p])
  );
} catch (e) {
  if (!prod) {
    const base = stagingEnv.GATSBY_CLOUD_FUNC_BASE;
    const paths = JSON.parse(stagingEnv.GATSBY_CLOUD_FUNC_PATHS);
    cloudFunctions = Object.fromEntries(
      Object.entries(paths).map(([k, p]) => [k, base + p])
    );
  }
}

try {
  mySrcmConfig = {
    ...JSON.parse(process.env.GATSBY_MYSRCM_CONFIG),
    baseClient: process.env.GATSBY_MYSRCM_BASE_CLIENTID,
    faceDeductApiUrl: process.env.GATSBY_AWS_LAMBDA_FACE_DEDUCT_API_URI,
    eventsClient: process.env.GATSBY_MYSRCM_EVENTS_CLIENTID,
    profileClient: process.env.GATSBY_MYSRCM_PROFILE_CLIENTID,
    preceptorCandidateAppClient:
      process.env.GATSBY_MYSRCM_PRECEPTORAPP_CLIENTID,
    firestoreClient: careapp
      ? process.env.GATSBY_MYSRCM_FIRESTORE_CLIENTID_CAREAPP
      : process.env.GATSBY_MYSRCM_FIRESTORE_CLIENTID,
  };
} catch (e) {
  if (!prod) {
    mySrcmConfig = {
      ...JSON.parse(stagingEnv.GATSBY_MYSRCM_CONFIG),
      baseClient: stagingEnv.GATSBY_MYSRCM_BASE_CLIENTID,
      faceDeductApiUrl: stagingEnv.GATSBY_AWS_LAMBDA_FACE_DEDUCT_API_URI,
      eventsClient: stagingEnv.GATSBY_MYSRCM_EVENTS_CLIENTID,
      profileClient: stagingEnv.GATSBY_MYSRCM_PROFILE_CLIENTID,
      preceptorCandidateAppClient:
        stagingEnv.GATSBY_MYSRCM_PRECEPTORAPP_CLIENTID,
      firestoreClient: careapp
        ? stagingEnv.GATSBY_MYSRCM_FIRESTORE_CLIENTID_CAREAPP
        : stagingEnv.GATSBY_MYSRCM_FIRESTORE_CLIENTID,
    };
  }
}

try {
  // need to be defined only if firestore is needed for the project
  if (careapp) {
    firebaseStoreConfig = JSON.parse(
      process.env.GATSBY_FIREBASE_STORE_CONFIG_CAREAPP
    );
  } else {
    firebaseStoreConfig = JSON.parse(process.env.GATSBY_FIREBASE_STORE_CONFIG);
  }
} catch (e) {
  if (!prod) {
    if (careapp) {
      firebaseStoreConfig = JSON.parse(
        stagingEnv.GATSBY_FIREBASE_STORE_CONFIG_CAREAPP
      );
    } else {
      firebaseStoreConfig = JSON.parse(stagingEnv.GATSBY_FIREBASE_STORE_CONFIG);
    }
  }
}

try {
  let pp = process.env.GATSBY_DOMAIN_PATHPREFIX || "";
  if (pp.trim() === "/") {
    pp = "";
  } else {
    pp = pp.trim().replace(/\/$/, "");
    if (pp) {
      pp = pp.replace(/^\//, "").replace(/^/, "/");
    }
  }

  siteDomain = process.env.GATSBY_DOMAIN;
  sitePathPrefix = pp;
  siteUrl = `https://${process.env.GATSBY_DOMAIN}${pp}`;

  if (!siteDomain) {
    throw Error("SITE DOMAIN not set");
  }
} catch (e) {
  if (!prod) {
    siteDomain = "localhost:8000";
    sitePathPrefix = "/";
    siteUrl = "http://localhost:8000";
  } else {
    throw e;
  }
}

let wpGrapqlUrl;
try {
  wpGrapqlUrl = process.env.GATSBY_DONATIONS_WORDPRESS_URL;

  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_DONATION_SERVICE not set");
  }

  donationServiceConfig = {
    url: process.env.GATSBY_DONATION_SERVICE,
    staticConf: process.env.GATSBY_DONATION_STATICCONF,
    captchaKey: process.env.GATSBY_CAPTCHA_KEY,
    digioApiUri: process.env.GATSBY_DIGIO_API_URI,
    digioRedirectUrl: process.env.GATSBY_DIGIO_REDIRECT_URL,
    digioSuccessUri: process.env.GATSBY_DIGIO_SUCCESS_URI,
    everyDropcountEmailUri: process.env.GATSBY_EVERY_DROP_COUNT_EMAIL_URI,
    moneriRedirectAckUri: process.env.GATSBY_MONERIS_REDIRECT_ACK_URI,
    monerisPaymentGatewayLib: process.env.GATSBY_MONERIS_PAYMENT_GATEWAY_LIB,
    donationClassicWpUrl: `https://${wpGrapqlUrl}`,
    siteDomain,
    siteUrl,
    sitePathPrefix,
    authorizeDotNetMakePaymentUri:
      process.env.GATSBY_AUTHORIZE_DOT_NET_MAKEPAYMENT_URI,
    authorizeDotNetRedirectAckUri:
      process.env.GATSBY_AUTHORIZE_DOT_NET_REDIRECT_ACK_URI_URI,
    authorizeDotNetPaymentGatewayLib:
      process.env.GATSBY_AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB,
    authorizeDotNetSrcmClientKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SRCM_CLIENT_KEY,
    authorizeDotNetSmsfClientKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SMSF_CLIENT_KEY,
    authorizeDotNetHiClientKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_HI_CLIENT_KEY,
    authorizeDotNetSrcmApiLoginKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SRCM_API_LOGIN_KEY,
    authorizeDotNetSmsfApiLoginKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SMSF_API_LOGIN_KEY,
    authorizeDotNetHiApiLoginKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_HI_API_LOGIN_KEY,
    smsfContactEmailId: process.env.GATSBY_SMSF_CONTACT_EMAILID,
    srcmContactEmailId: process.env.GATSBY_SRCM_CONTACT_EMAILID,
    contactFormCaptchaKey: process.env.GATSBY_CONTACT_FORM_CAPTCHA_KEY,
    gatsbyDonationRecurringServiceBaseUri:
      process.env.GATSBY_DONATION_RECURRING_SERVICE_BASE_URI,
    authorizeDotNetRecurringPreSubscribe:
      process.env.GATSBY_AUTHORIZE_DOT_NET_RECURRING_PRESUBSCRIBE,
  };
} catch (e) {
  if (!prod) {
    wpGrapqlUrl = stagingEnv.GATSBY_DONATIONS_WORDPRESS_URL;

    if (!stagingEnv.GATSBY_DONATION_SERVICE) {
      throw Error("GATSBY_DONATION_SERVICE not set");
    }

    donationServiceConfig = {
      url: stagingEnv.GATSBY_DONATION_SERVICE,
      staticConf: stagingEnv.GATSBY_DONATION_STATICCONF,
      captchaKey: stagingEnv.GATSBY_CAPTCHA_KEY,
      digioApiUri: stagingEnv.GATSBY_DIGIO_API_URI,
      digioRedirectUrl: stagingEnv.GATSBY_DIGIO_REDIRECT_URL,
      digioSuccessUri: stagingEnv.GATSBY_DIGIO_SUCCESS_URI,
      everyDropcountEmailUri: stagingEnv.GATSBY_EVERY_DROP_COUNT_EMAIL_URI,
      moneriRedirectAckUri: stagingEnv.GATSBY_MONERIS_REDIRECT_ACK_URI,
      monerisPaymentGatewayLib: stagingEnv.GATSBY_MONERIS_PAYMENT_GATEWAY_LIB,
      donationClassicWpUrl: `https://${wpGrapqlUrl}`,
      siteDomain,
      siteUrl,
      sitePathPrefix,
      authorizeDotNetMakePaymentUri:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_MAKEPAYMENT_URI,
      authorizeDotNetRedirectAckUri:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_REDIRECT_ACK_URI_URI,
      authorizeDotNetPaymentGatewayLib:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB,
      authorizeDotNetSrcmClientKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SRCM_CLIENT_KEY,
      authorizeDotNetSmsfClientKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SMSF_CLIENT_KEY,
      authorizeDotNetHiClientKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_HI_CLIENT_KEY,
      authorizeDotNetSrcmApiLoginKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SRCM_API_LOGIN_KEY,
      authorizeDotNetSmsfApiLoginKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SMSF_API_LOGIN_KEY,
      authorizeDotNetHiApiLoginKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_HI_API_LOGIN_KEY,
      smsfContactEmailId: stagingEnv.GATSBY_SMSF_CONTACT_EMAILID,
      srcmContactEmailId: stagingEnv.GATSBY_SRCM_CONTACT_EMAILID,
      contactFormCaptchaKey: stagingEnv.GATSBY_CONTACT_FORM_CAPTCHA_KEY,
      gatsbyDonationRecurringServiceBaseUri:
        stagingEnv.GATSBY_DONATION_RECURRING_SERVICE_BASE_URI,
      authorizeDotNetRecurringPreSubscribe:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_RECURRING_PRESUBSCRIBE,
    };
  } else {
    throw Error(e);
  }
}

try {
  myDomain = `https://${process.env.GATSBY_DOMAIN}`;
} catch {
  myDomain = "";
}

if (!prod && (!myDomain || myDomain === "https://undefined")) {
  myDomain = "http://localhost:8000";
}

let domainReg;
let domainApps;
let domainDonations;
let domainMy;
let authStaticConfig;
domainReg = `https://${process.env.GATSBY_DOMAIN_REG}`;
domainApps = `https://${process.env.GATSBY_DOMAIN_APPS}`;
domainDonations = `https://${process.env.GATSBY_DOMAIN_DONATIONS}`;
domainMy = `https://${process.env.GATSBY_DOMAIN_MY}`;
authStaticConfig = process.env.GATSBY_AUTH_STATICCONF;

if (!prod) {
  if (!domainReg || domainReg === "https://undefined") {
    domainReg = `https://${stagingEnv.GATSBY_DOMAIN_REG}`;
  }
  if (!domainApps || domainApps === "https://undefined") {
    domainApps = `https://${stagingEnv.GATSBY_DOMAIN_APPS}`;
  }
  if (!domainDonations || domainDonations === "https://undefined") {
    domainDonations = `https://${stagingEnv.GATSBY_DOMAIN_DONATIONS}`;
  }
  if (!domainMy || domainMy === "https://undefined") {
    domainMy = `https://${stagingEnv.GATSBY_DOMAIN_MY}`;
  }
  if (!authStaticConfig) {
    authStaticConfig = stagingEnv.GATSBY_AUTH_STATICCONF;
  }
}

const getMandatoryEnv = (l = []) => {
  const config = {
    donationServiceConfig,
    firebaseAuthConfig,
    firebaseStoreConfig,
    // firebaseServiceConfig,
    mySrcmConfig,
    domainReg,
    domainApps,
    domainDonations,
    domainMy,
    authStaticConfig,
    wpGrapqlUrl,
    siteUrl,
    cloudFunctions,
    keycloakConfig,
    myloginType,
  };
  l.forEach((element) => {
    if (!config[element]) {
      throw Error(`ERROR: CONFIG ${element} IS MISSING`);
    }
  });
  return config;
};

module.exports = getMandatoryEnv;
