import { unsetUser } from "gatsby-plugin-hfn-profile/state/actions";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { keycloakConfig } = getMandatoryEnv(["keycloakConfig"]);

const KeycloakAuth = () => {
  const elementRef = useRef();
  const dispatch = useDispatch();
  React.useEffect(() => {
    import("hfn-oneauth/main");
  });
  const oneauthLogout = useCallback(async () => {
    const params = keycloakConfig;
    if (typeof window !== "undefined") {
      const { userLogout } = await import("hfn-oneauth/main");
      await userLogout(params);
      dispatch(unsetUser());
      return true;
    }
    return false;
  }, [dispatch]);
  const handleUserLoggedOut = useCallback(
    async (event) => {
      if (event?.detail?.loggedOut) await oneauthLogout();
    },
    [oneauthLogout]
  );
  useEffect(() => {
    elementRef.current = document.querySelector("hfn-oneauth");

    // Add the event listener when the element is present
    if (elementRef.current) {
      elementRef.current.addEventListener("userLoggedOut", handleUserLoggedOut);
    }

    // Cleanup event listener on unmount or when the path changes
    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener(
          "userLoggedOut",
          handleUserLoggedOut
        );
      }
    };
  }, [elementRef, handleUserLoggedOut]);
  return (
    <hfn-oneauth
      ref={elementRef}
      config={JSON.stringify(keycloakConfig)}
      showCancel
      additionalParam="&a=b"
    />
  );
};

export default KeycloakAuth;
